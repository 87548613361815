import { css } from '@emotion/core'
import { fontFamily } from './fontFamily'
import 'normalize.css'

export const global = css`
  body {
    font-family: ${fontFamily};
  }
  a {
    word-break: break-all;
  }
`
