import { css, keyframes } from '@emotion/core'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const fadeInAnimation = css`
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
  animation-fill-mode: both;
`
