import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import * as s from './circle-image.styles'
import { Image } from '@common/components'

const query = graphql`
  query {
    circleImage: file(relativePath: { eq: "icons/circle.png" }) {
      childImageSharp {
        fluid(maxWidth: 188) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const CircleImage: React.StatelessComponent = ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <s.Container>
        <Image image={data.circleImage.childImageSharp.fluid} width="110px">
          {children}
        </Image>
      </s.Container>
    )}
  />
)
