import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Section, Title } from '@common/components'
import { routes, routeIds, socialLinks } from '@common/constants'
import * as s from './community.styles'
import { Thumbnail } from './thumbnail'
import { colors } from '@theme'

const StyledThumbnail = s.Thumbnail.withComponent(Thumbnail)

const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/community.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    github: file(relativePath: { eq: "icons/github.png" }) {
      childImageSharp {
        fluid(maxWidth: 148) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    youtube: file(relativePath: { eq: "icons/youtube.png" }) {
      childImageSharp {
        fluid(maxWidth: 148) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blog: file(relativePath: { eq: "icons/blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 148) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Community: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={({ background, github, youtube, blog }) => (
      <Section
        id={routeIds.community}
        backgroundColor={colors.light}
        backgrounds={{ bottom: background.childImageSharp.fluid }}
      >
        <Title>Community</Title>
        <s.Thumbnails>
          <StyledThumbnail
            imageFluid={github.childImageSharp.fluid}
            title="GitHub"
            description="Check out our Lemoncode GitHub page"
            link={socialLinks.github}
          />
          {false && (
            <StyledThumbnail
              imageFluid={youtube.childImageSharp.fluid}
              title="YouTube Channel"
              description="Check out our YouTube channel"
              link={socialLinks.youtube}
            />
          )}
          <StyledThumbnail
            imageFluid={blog.childImageSharp.fluid}
            title="Blog"
            description="Check out our blog"
            link={routes.blog}
          />
        </s.Thumbnails>
      </Section>
    )}
  />
)
