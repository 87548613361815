import * as React from 'react'
import { Section, Title } from '@common/components'
import * as s from './post.styles'

interface Props {
  title: string
  body
}

export const Post: React.StatelessComponent<Props> = ({ title, body }) => (
  <Section>
    <Title>{title}</Title>
    <s.Body dangerouslySetInnerHTML={{ __html: body }} />
  </Section>
)
