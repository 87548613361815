import * as React from 'react'
import * as s from './thumbnail.styles'

const Container = s.Container.withComponent('a')

interface Props {
  title: string
  description?
  className?: string
}

export const Thumbnail: React.StatelessComponent<Props> = ({
  title,
  description,
  className,
}) => (
  <Container className={className}>
    <s.Title>{title} </s.Title>
    <s.Description>{description}</s.Description>
  </Container>
)
