import { styled } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Header = styled.div`
  z-index: 2;
  flex-shrink: 0;
  flex-basis: 0;
  position: sticky;
  top: -3px;
`

export const Content = styled.div`
  flex-grow: 1;
  height: 100%;
`
