import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Image } from '@common/components'

import * as s from './logo.styles'
const Container = s.Container.withComponent(Image)

const iconQuery = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "logos/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

interface Props {
  to: string
}

export const Logo: React.StatelessComponent<Props> = ({ to }) => (
  <StaticQuery
    query={iconQuery}
    render={data => (
      <Link to={to}>
        <Container
          width="240px"
          image={data.placeholderImage.childImageSharp.fluid}
        />
      </Link>
    )}
  />
)
