import { styled } from '@styles'
import { css } from '@emotion/core'
import { theme } from '@theme'

export const titleStyles = css`
  color: ${theme.colors.light};
`

export const dividerStyles = css`
  fill: ${theme.colors.light};
`

export const Subtitle = styled.p`
  color: ${theme.colors.light};
  margin-bottom: 4rem;
`
