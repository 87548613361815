import { styled } from '@styles'

export const Posts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
`

export const Post = styled.div`
  margin-top: 3rem;
`
