import { styled } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h3`
  display: flex;
  margin-bottom: 0;
`
export const TitleOne = styled.span`
  ${({ theme }) => theme.typography.thumbnail.title}
`

export const TitleTwo = styled.span`
  ${({ theme }) => theme.typography.thumbnail.title}
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
`

export const Description = styled.p`
  ${({ theme }) => theme.typography.thumbnail.description}
  margin-bottom: 0;
`

export const Image = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
