import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Section, Title } from '@common/components'
import { mapToPosts } from './mappers'
import { Post } from './post'
import * as s from './blog.styles'

const StyledPost = s.Post.withComponent(Post)

const query = graphql`
  query {
    postQuery: allContentfulPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          createdAt
          title
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          path
        }
      }
    }
  }
`

export const Blog: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={({ postQuery }) => (
      <Section>
        <Title>Basefactor Blog</Title>
        <s.Posts>
          {mapToPosts(postQuery.edges).map(post => (
            <StyledPost key={post.id} post={post} />
          ))}
        </s.Posts>
      </Section>
    )}
  />
)
