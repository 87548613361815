import * as React from 'react'
import * as s from './title.styles'

interface Props {
  title: string
  subtitle: React.ReactNode
  className?
}

export const Title: React.StatelessComponent<Props> = ({
  title,
  subtitle,
  className,
}) => (
  <s.Container className={className}>
    <s.Title>{title}</s.Title>
    <s.Subtitle>{subtitle}</s.Subtitle>
  </s.Container>
)
