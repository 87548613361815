import * as React from 'react'

interface Props {
  className?: string
  onClick: () => void
}

export const MenuIcon: React.StatelessComponent<Props> = ({
  className,
  onClick,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="53px"
    height="53px"
    viewBox="0 0 53 53"
    xmlSpace="preserve"
    enableBackground="new 0 0 53 53"
    className={className}
    onClick={onClick}
  >
    <g>
      <path d="M2,13.5h49c1.104,0,2-0.896,2-2s-0.896-2-2-2H2c-1.104,0-2,0.896-2,2S0.896,13.5,2,13.5z" />
      <path d="M2,28.5h49c1.104,0,2-0.896,2-2s-0.896-2-2-2H2c-1.104,0-2,0.896-2,2S0.896,28.5,2,28.5z" />
      <path d="M2,43.5h49c1.104,0,2-0.896,2-2s-0.896-2-2-2H2c-1.104,0-2,0.896-2,2S0.896,43.5,2,43.5z" />
    </g>
  </svg>
)
