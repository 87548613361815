import { styled } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const MediaContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-around;
`

export const MedaLink = styled.a`
  text-decoration: none;
`
