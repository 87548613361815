import { styled } from '@styles'
import { css } from '@emotion/core'

const buttonMaterialUIStyles = css`
  padding: 8px 24px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  border-radius: 4px;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
`

export const Container = styled.button`
  && {
    ${({ theme }) => theme.typography.button};
    background-color: ${({ theme }) => theme.colors.dark};
    text-transform: capitalize;
    ${buttonMaterialUIStyles};

    &:hover {
      background-color: ${({ theme }) => theme.colors.dark};
      opacity: 0.9;
      text-decoration: none;
    }
  }
`
