import * as React from 'react'
import * as s from './button.styles'

interface Props {
  label
  type?: string
  onClick?: () => void
}

export const Button: React.StatelessComponent<Props> = ({
  label,
  type,
  onClick,
}) => (
  <s.Container type={type} onClick={onClick}>
    {label}
  </s.Container>
)

Button.defaultProps = {
  type: 'button',
}
