import { styled, media, headerShadow } from '@styles'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 4rem;
  background: ${({ theme }) => theme.colors.primary};
  ${headerShadow};

  ${media.large`
    justify-content: space-around;
  `}
`

export const MenuIcon = styled.image`
  width: 2rem;
  height: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  fill: ${({ theme }) => theme.colors.light};
`
