import { styled, media } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.medium`
    flex-direction: row;
  `}
`

export const TitleContainer = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;

  ${media.medium`
  flex-basis: 0;
  `}
`

export const DescriptionContainer = styled.div`
  flex-grow: 2;
  flex-basis: auto;
  display: flex;
  flex-direction: column;

  ${media.medium`
    flex-basis: 0;
    margin-left: 1rem;
  `}
`

interface DateProps {
  imageSrc: string
}

export const Date = styled.h3<DateProps>`
  ${({ theme }) => theme.typography.thumbnail.subtitle};
  text-align: left;
  margin-bottom: 0;
  ${({ imageSrc }) => `
    &::before {
      content: ' ';
      background-image: url(${imageSrc});
      background-size: 1.2rem;
      background-repeat: no-repeat;
      display: inline-flex;
      align-items: center;
      width: 24px;
      height: 30px;
      vertical-align: middle;
      margin-top: 0.5rem;
    }
  `}
`

export const Title = styled.h3`
  ${({ theme }) => theme.typography.blog.postTitle};
  margin-top: 1rem;
`

export const Description = styled.span`
  ${({ theme }) => theme.typography.body}
  text-align: left;
`

export const PostLink = styled.a`
  text-decoration: none;
`
