import { format } from 'date-fns'
import { Post } from './viewModel'

export const mapToPosts = (posts: any[]): Post[] =>
  Array.isArray(posts) ? posts.map(mapToPost) : []

const mapToPost = (post): Post =>
  Boolean(post)
    ? {
        id: post.node.id,
        date: format(post.node.createdAt, 'MMMM Do YYYY'),
        title: post.node.title,
        description: post.node.excerpt.childMarkdownRemark.html,
        link: formatLink(post.node.path),
      }
    : null

const formatLink = (path: string): string =>
  hasSlash(path) ? path : `/${path}`

const hasSlash = (path: string) => Boolean(path.slice(0, 1) === '/')
