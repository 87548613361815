import { styled, media } from '@styles'

export const Thumbnails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${media.large`
    flex-direction: row;
    justify-content: space-around;
  `}
`

export const Thumbnail = styled.div`
  margin-top: 4rem;
  width: 100%;
`
