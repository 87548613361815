import { css } from '@emotion/core'
import { styled, media } from '@styles'
import { colors } from '@theme'

export const sectionStyles = css`
  background-color: ${colors.primary};

  ${media.medium`
    background-color: transparent;
    padding-bottom: 3rem;
  `};

  ${media.large`
    padding-bottom: 8rem;
  `};
`

export const backgroundTopStyles = css`
  display: none;

  ${media.medium`
    display: block;
    position: absolute;
    z-index: -1;
  `};
`
export const backgroundBottomStyles = css`
  display: none;

  ${media.large`
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -2px;
  `};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  ${media.medium`
    margin-top: 4rem;
  `};

  ${media.large`
    margin-top: 8rem;
  `};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.large`
    justify-content: flex-start;
  `};
`
export const Subtitle = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0;
`

export const LeftBracket = styled.image`
  display: none;

  ${media.small`
    display: block;
  `};
`

export const RightBracket = styled.image`
  display: none;

  ${media.small`
    display: block;
    transform: rotateY(180deg);
  `};
`

export const Computer = styled.image`
  display: none;

  ${media.large`
    display: block;
    margin-left: 1rem;
  `};

  ${media.extraLarge`
    margin-left: 0rem;
  `};
`
