import { styled, media, headerShadow, clearBorder } from '@styles'
import { css } from '@emotion/core'

interface Props {
  animation?
}

const addBorder = color => css`
  border-width: 1px;
  border-style: solid;
  border-color: ${color};
`

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  box-sizing: border-box;
  outline: none;
  ${({ animation }) => (animation ? animation : '')};
  ${({ theme }) => addBorder(theme.colors.secondary)};
  ${headerShadow};

  ${media.large`
    position: static;
    animation: none;
    width: auto;
    display: flex;
    flex-direction: row;
    ${clearBorder};
    box-shadow: none;
  `}
`

export const MenuIcon = styled.image`
  ${media.large`
    display: none;
  `};
`

export const Link = styled.a`
  padding: 1rem;
  ${({ theme }) => addBorder(theme.colors.secondary)};

  ${media.large`
    padding: 0;
    margin-left: 1.5rem;
    ${clearBorder};
  `}
`
