import * as React from 'react'
import { fadeInAnimation } from '@styles'
import { routes } from '@common/constants'
import { Link } from './link'
import * as s from './menu.styles'

const MenuLink = s.Link.withComponent(Link)

interface Props {
  isOpen: boolean
  isLargeResolution: boolean
  onMenuClick: () => void
  onBlur
}

export class Menu extends React.PureComponent<Props> {
  private menuRef = React.createRef<HTMLDivElement>()
  
  constructor(props) {
    super(props);
  }

  public componentDidUpdate() {
    if (this.hasToShowMenu()) {
      this.menuRef.current.focus()
    }
  }

  hasToShowMenu = () => this.props.isLargeResolution || this.props.isOpen

  render() {
    const { onMenuClick, onBlur } = this.props

    return (
      this.hasToShowMenu() && (
        <s.Container
          tabIndex={-1}
          ref={this.menuRef}
          animation={fadeInAnimation}
          onBlur={onBlur}
        >
          <MenuLink to={routes.home} onClick={onMenuClick} title="Home" />
          <MenuLink
            to={routes.aboutUs}
            onClick={onMenuClick}
            title="About Us"
          />
          <MenuLink to={routes.team} onClick={onMenuClick} title="Team" />
          <MenuLink
            to={routes.articlesAndVideos}
            onClick={onMenuClick}
            title="Articles & Videos"
          />
          <MenuLink
            to={routes.community}
            onClick={onMenuClick}
            title="Community"
          />
          <MenuLink
            to={routes.getInTouch}
            onClick={onMenuClick}
            title="Get in touch"
          />
          <MenuLink to={routes.blog} onClick={onMenuClick} title="Blog" />
        </s.Container>
      )
    )
  }
}
