import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { routeIds } from '@common/constants'
import { Section, Title, Subtitle } from '@common/components'
import { colors } from '@theme'
import { Thumbnail } from './thumbnail'
import { mapMemberModelToVM } from './mapper'
import * as s from './team.styles'

const StyledThumbnail = s.Thumbnail.withComponent(Thumbnail)

const query = graphql`
  query Team {
    allContentfulMember(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          role
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

export const Team: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Section id={routeIds.team} backgroundColor={colors.secondary}>
        <Title>Team</Title>
        <Subtitle>
          Our collective expertise, a team of passionate developers,
          designers and trainers, all at your service to create top of the bill
          experiences.
        </Subtitle>
        <s.Thumbnails>
          {data.allContentfulMember.edges.map(edge => (
            <StyledThumbnail
              key={edge.node.id}
              member={mapMemberModelToVM(edge)}
            />
          ))}
        </s.Thumbnails>
      </Section>
    )}
  />
)
