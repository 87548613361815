import { styled } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h3`
  ${({ theme }) => theme.typography.thumbnail.title}
  margin-bottom: 0;
`

export const Description = styled.span`
  ${({ theme }) => theme.typography.thumbnail.description}
  margin-bottom: 0;
`
