import { ContactInfo } from './viewModel'

const encodeFormBody = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const sendEmail = (
  contactInfo: ContactInfo,
  formName: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encodeFormBody({
      'form-name': formName,
      ...contactInfo,
    }),
  })
    .then(() => {
      onSuccess()
    })
    .catch(() => {
      onFailure()
    })
}
