import * as React from 'react'
import Media from 'react-responsive'

interface Props {
  breakpoint: number
}

export const MediaQuery: React.StatelessComponent<Props> = ({
  breakpoint,
  children,
}) => <Media minWidth={breakpoint}>{children}</Media>
