import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Image } from '@common/components'
import { socialLinks } from '@common/constants'
import * as s from './social-media.styles'

interface Props {
  className?: string
}

const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 222) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twitter: file(relativePath: { eq: "icons/twitter-social.png" }) {
      childImageSharp {
        fluid(maxWidth: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    youtube: file(relativePath: { eq: "icons/youtube-social.png" }) {
      childImageSharp {
        fluid(maxWidth: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    linkedin: file(relativePath: { eq: "icons/linkedin-social.png" }) {
      childImageSharp {
        fluid(maxWidth: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const SocialMedia: React.StatelessComponent<Props> = ({ className }) => (
  <StaticQuery
    query={query}
    render={({ logo, twitter, youtube, linkedin }) => (
      <s.Container className={className}>
        <Image width="222px" image={logo.childImageSharp.fluid} />
        <s.MediaContainer>
          <s.MedaLink href={socialLinks.twitter} target="_blank">
            <Image width="40px" image={twitter.childImageSharp.fluid} />
          </s.MedaLink>
          {false && (
            <s.MedaLink href={socialLinks.youtube} target="_blank">
              <Image width="40px" image={youtube.childImageSharp.fluid} />
            </s.MedaLink>
          )}
          <s.MedaLink href={socialLinks.linkedin} target="_blank">
            <Image width="40px" image={linkedin.childImageSharp.fluid} />
          </s.MedaLink>
        </s.MediaContainer>
      </s.Container>
    )}
  />
)
