import * as React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Input, TextArea, Button, Row } from '@common/components'
import * as s from './form.styles'
import {
  ContactInfo,
  createDefaultContactInfo,
  ContactInfoError,
  createDefaultContactInfoError,
} from './viewModel'
import { validations } from './validations'
import * as business from './form.business'

interface State {
  contactInfo: ContactInfo
  contactInfoError: ContactInfoError
  isSentContactInfo: boolean
  message: string
}

export class Form extends React.PureComponent<{}, State> {
  state: State = {
    contactInfo: createDefaultContactInfo(),
    contactInfoError: createDefaultContactInfoError(),
    isSentContactInfo: false,
    message: '',
  }
  
  constructor(props) {
    super(props);
  }

  onChangeHandler = (value: string, field: string) => {
    validations
      .validateField(this.state.contactInfo, field, value)
      .then(result => {
        this.setState({
          ...this.state,
          contactInfo: {
            ...this.state.contactInfo,
            [field]: value,
          },
          contactInfoError: {
            ...this.state.contactInfoError,
            [field]: result,
          },
        })
      })
  }

  onSubmitHandler = event => {
    event.preventDefault()
    const formName = event.target.getAttribute('name')

    validations.validateForm(this.state.contactInfo).then(result => {
      this.setState({
        contactInfoError: {
          ...this.state.contactInfoError,
          ...result.fieldErrors,
        },
      })

      if (result.succeeded) {
        this.sendEmail(formName)
      }
    })
  }

  sendEmail = formName => {
    business.sendEmail(
      this.state.contactInfo,
      formName,
      () => {
        this.setState({
          contactInfo: createDefaultContactInfo(),
          contactInfoError: createDefaultContactInfoError(),
          isSentContactInfo: true,
          message: 'Thank you for contact with us!',
        })
      },
      () => {
        this.setState({
          contactInfo: createDefaultContactInfo(),
          contactInfoError: createDefaultContactInfoError(),
          isSentContactInfo: true,
          message: 'Please try later, something was wrong :(',
        })
      }
    )
  }

  onCloseMessage = () => {
    this.setState({ isSentContactInfo: false })
  }

  render() {
    return (
      <s.Container
        name="basefactor-get-in-touch-form"
        method="POST"
        data-netlify={true}
        data-netlify-honeypot="bot-field"
        onSubmit={this.onSubmitHandler}
      >
        <Row>
          <Input
            name="name"
            label="Name"
            value={this.state.contactInfo.name}
            onChange={this.onChangeHandler}
            hasError={!this.state.contactInfoError.name.succeeded}
            error={this.state.contactInfoError.name.errorMessage}
          />
        </Row>
        <Row>
          <Input
            name="email"
            label="Email"
            value={this.state.contactInfo.email}
            onChange={this.onChangeHandler}
            hasError={!this.state.contactInfoError.email.succeeded}
            error={this.state.contactInfoError.email.errorMessage}
          />
        </Row>
        <Row>
          <TextArea
            name="inquiry"
            label="Inquiry"
            value={this.state.contactInfo.inquiry}
            onChange={this.onChangeHandler}
            hasError={!this.state.contactInfoError.inquiry.succeeded}
            error={this.state.contactInfoError.inquiry.errorMessage}
          />
        </Row>
        <Row>
          <Button type="submit" label="Send" />
        </Row>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.isSentContactInfo}
          autoHideDuration={3000}
          onClose={this.onCloseMessage}
          message={this.state.message}
        />
      </s.Container>
    )
  }
}
