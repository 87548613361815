import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Section, Title, Subtitle } from '@common/components'
import { routeIds } from '@common/constants'
import { colors } from '@theme'
import { Carousel } from './carousel'
import { mapPostsToArticles } from './mappers'
import * as s from './articles.styles'

const StyledSubtitle = s.Subtitle.withComponent(Subtitle)

const query = graphql`
  query {
    post: allContentfulPost(
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          path
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          createdAt
        }
      }
    }
  }
`

export const Articles: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={({ post }) => (
      <Section id={routeIds.articlesAndVideos} backgroundColor={colors.primary}>
        <Title classNames={{ title: s.titleStyles, divider: s.dividerStyles }}>
          Featured Articles & Videos
        </Title>
        <StyledSubtitle>
          Have a look at how we work in these blogs, video and github
          repositories.
        </StyledSubtitle>
        <Carousel articles={mapPostsToArticles(post.edges)} />
      </Section>
    )}
  />
)
