import * as React from 'react'
import { Divider } from './divider'
import * as s from './title.styles'

const StyledDivider = s.Divider.withComponent(Divider)

interface Props {
  classNames?: {
    title?
    divider?
  }
}

export const Title: React.StatelessComponent<Props> = ({
  children,
  classNames,
}) => (
  <s.Container>
    <s.Title className={classNames.title}>{children}</s.Title>
    <StyledDivider className={classNames.divider} />
  </s.Container>
)

Title.defaultProps = {
  classNames: {
    title: '',
    divider: '',
  },
}
