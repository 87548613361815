import * as React from 'react'
import { Image } from '@common/components'
import { Member } from './viewModel'
import * as s from './thumbnail.styles'

const StyledImage = s.Image.withComponent(Image)

interface Props {
  member: Member
  className?: string
}

export const Thumbnail: React.StatelessComponent<Props> = ({
  member: { name, role, description, imageFluid },
  className,
}) => (
  <s.Container className={className}>
    <StyledImage width="120px" image={imageFluid} />
    <s.Title>{name}</s.Title>
    <s.Subtitle>{role}</s.Subtitle>
    <s.Description dangerouslySetInnerHTML={{ __html: description }} />
  </s.Container>
)
