import { styled, media } from '@styles'

export const Container = styled.div`
  display: none;

  ${media.large`
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `}
`
