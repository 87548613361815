import { styled } from '@styles'

export const Container = styled.div`
  position: relative;
`

export const ImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
