import * as React from 'react'
import * as s from './card-actions.styles'

interface Props {
  className?
}

export const CardActions: React.StatelessComponent<Props> = ({
  className,
  children,
}) => <s.Container className={className}>{children}</s.Container>
