import { styled, headerShadow } from '@styles'

export const CardImage = styled.image`
  max-height: 10rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.secondary};
  ${headerShadow};
`

interface DateProps {
  imageSrc: string
}

export const Date = styled.h3<DateProps>`
  ${({ theme }) => theme.typography.thumbnail.subtitle};
  text-align: left;
  margin-bottom: 0;
  ${({ imageSrc }) => `
    &::before {
      content: ' ';
      background-image: url(${imageSrc});
      background-size: 1.2rem;
      background-repeat: no-repeat;
      display: inline-flex;
      align-items: center;
      width: 24px;
      height: 30px;
      vertical-align: middle;
      margin-top: 0.5rem;
    }
  `}
`

export const Title = styled.h3`
  ${({ theme }) => theme.typography.thumbnail.title};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 0;
  text-align: left;
`

export const Description = styled.span`
  ${({ theme }) => theme.typography.thumbnail.description}
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;

  & > p {
    margin: 0;
    margin-top: 0.5rem;
  }
`

export const ArticleLink = styled.a`
  text-decoration: none;
`
