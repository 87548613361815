import { createStyles } from '@material-ui/core/styles'
import { styled } from '@styles'
import { colors, weights } from '@theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Error = styled.span`
  ${({ theme }) => theme.typography.formError};
  margin-left: 0.8rem;
`

export const textFieldStyles = () =>
  createStyles({
    label: {
      fontFamily: 'Open sans',
      fontSize: '1rem',
      fontWeight: weights.regular,
      color: colors.dark,
      '&$focused': {
        color: colors.primary,
      },
      '&$error': {
        color: colors.error,
      },
    },
    input: {
      '&$input $notchedOutline': {
        borderColor: colors.primary,
      },
      '&$focused $notchedOutline': {
        borderColor: colors.primary,
      },
      '&$error $notchedOutline': {
        borderColor: colors.error,
      },
    },
    focused: {},
    error: {},
    notchedOutline: {},
  })
