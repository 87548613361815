import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import {
  Image,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@common/components'
import { Article } from './viewModel'
import * as s from './thumbnail.styles'

const CardImage = s.CardImage.withComponent(Image)
const ArticleLink = s.ArticleLink.withComponent(Link)

const query = graphql`
  query {
    calendar: file(relativePath: { eq: "icons/calendar.png" }) {
      childImageSharp {
        fluid(maxWidth: 16) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

interface Props {
  article: Article
  className?: string
}

export const Thumbnail: React.StatelessComponent<Props> = ({
  article,
  className,
}) => (
  <StaticQuery
    query={query}
    render={({ calendar }) => (
      <Card className={className}>
        <CardImage
          width={article.imageFluid.presentationWidth}
          image={article.imageFluid}
        />
        <CardContent>
          <s.Date imageSrc={calendar.childImageSharp.fluid.src}>
            {article.date}
          </s.Date>
          <s.Title>{article.title}</s.Title>
          <s.Description
            dangerouslySetInnerHTML={{ __html: article.description }}
          />
        </CardContent>
        <CardActions>
          <ArticleLink to={article.link}>
            <Button label="Read more" />
          </ArticleLink>
        </CardActions>
      </Card>
    )}
  />
)
