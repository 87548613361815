import { styled, media } from '@styles'

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.light};

  ${media.large`
    max-width: ${100 / 4 - 5}%;
  `};
`

export const Image = styled.image`
  position: absolute;
  top: -3rem;
`

export const Title = styled.p`
  ${({ theme }) => theme.typography.thumbnail.title}
  margin-bottom: 0;
`
export const Subtitle = styled.p`
  ${({ theme }) => theme.typography.thumbnail.subtitle}
  margin-top: 0.2rem;
  margin-bottom: 0;
`
export const Description = styled.span`
  ${({ theme }) => theme.typography.thumbnail.description}
  margin-bottom: 0;
`
