import * as React from 'react'

interface Props {
  className?: string
}

export const Divider: React.StatelessComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="232px"
    height="2px"
    viewBox="0 0 232 2"
    enableBackground="new 0 0 232 2"
    xmlSpace="preserve"
  >
    <path
      className={className}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0,1c0-0.547,51.941-1,116-1c64.073,0,116,0.453,116,1
	s-51.927,1-116,1C51.941,2,0,1.547,0,1z"
    />
  </svg>
)
