import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Section } from '@common/components'
import { Thumbnail } from './thumbnail'
import * as s from './footer.styles'
import { SocialMedia } from './social-media'
import { colors } from '@theme'

const StyledThumbnail = s.Thumbnail.withComponent(Thumbnail)

const StyledSocialMedia = s.SocialMedia.withComponent(SocialMedia)

const query = graphql`
  query {
    backgroundTop: file(relativePath: { eq: "backgrounds/footer-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundBottom: file(
      relativePath: { eq: "backgrounds/footer-bottom.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

interface Props {
  enableTopBackground?: boolean
}

export const Footer: React.StatelessComponent<Props> = ({
  enableTopBackground,
}) => (
  <StaticQuery
    query={query}
    render={({ backgroundTop, backgroundBottom }) => (
      <Section
        backgroundColor={colors.light}
        backgrounds={{
          top: enableTopBackground && backgroundTop.childImageSharp.fluid,
          bottom: backgroundBottom.childImageSharp.fluid,
        }}
      >
        <s.Thumbnails>
          <StyledThumbnail title="Doers/" />
          <StyledThumbnail
            title="Location/"
            description={
              <s.ContactMedia>
                C/ Pintor Martínez Cubells 5 Málaga (Spain)
              </s.ContactMedia>
            }
          />
          <StyledThumbnail
            title="General enquiries/"
            description={
              <>
                <s.ContactMedia>info@lemoncode.net</s.ContactMedia>
                <s.ContactMedia>+34 693 84 24 54</s.ContactMedia>
              </>
            }
          />
        </s.Thumbnails>
        <StyledSocialMedia />
        <s.Copyright>
          <span>Copyright 2018 </span>
          <b>Basefactor</b>
          <span>. All Rights Reserved.</span>
        </s.Copyright>
      </Section>
    )}
  />
)

Footer.defaultProps = {
  enableTopBackground: true,
}
