import * as React from 'react'
import Img from 'gatsby-image'
import * as s from './section.styles'
import { BackgroundImageContainer } from './background-image'

interface Props {
  id?: string
  backgrounds?: {
    bottom?
    top?
  }
  backgroundColor?: string
  classNames?: {
    root?
    backgrounds?: {
      top?
      bottom?
    }
  }
}

export const Section: React.StatelessComponent<Props> = ({
  children,
  id,
  backgrounds: { bottom, top },
  backgroundColor,
  classNames,
}) => (
  <s.Container
    className={classNames.root}
    id={id}
    backgroundColor={backgroundColor}
  >
    {top && (
      <BackgroundImageContainer className={classNames.backgrounds.top} top="0">
        <Img fluid={top} />
      </BackgroundImageContainer>
    )}
    <s.BodyContainer>{children}</s.BodyContainer>
    {bottom && (
      <BackgroundImageContainer
        className={classNames.backgrounds.bottom}
        bottom="0"
      >
        <Img fluid={bottom} />
      </BackgroundImageContainer>
    )}
  </s.Container>
)

Section.defaultProps = {
  backgrounds: {
    top: null,
    bottom: null,
  },
  classNames: {
    root: null,
    backgrounds: {
      top: null,
      bottom: null,
    },
  },
}
