export interface Colors {
  primary: string
  secondary: string
  dark: string
  light: string
  error: string
}

export const colors: Colors = {
  primary: '#d9d900',
  secondary: '#f5f7fb',
  dark: '#333326',
  light: '#ffffff',
  error: '#f44336',
}
