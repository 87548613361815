import * as React from 'react'
import { Thumbnail } from './thumbnail'
import * as s from './carousel.styles'
import { Article } from './viewModel'

const StyledThumbnail = s.Thumbnail.withComponent(Thumbnail)

interface Props {
  articles: Article[]
}

export const Carousel: React.StatelessComponent<Props> = ({ articles }) => (
  <s.Container>
    <s.Thumbnails>
      {articles.map(article => (
        <StyledThumbnail key={article.id} article={article} />
      ))}
    </s.Thumbnails>
  </s.Container>
)
