import * as React from 'react'
import { Section, Title, Subtitle } from '@common/components'
import { routeIds } from '@common/constants'
import { colors } from '@theme'
import { Form } from './form'

export const GetInTouch: React.StatelessComponent = () => (
  <Section id={routeIds.getInTouch} backgroundColor={colors.secondary}>
    <Title>Get in Touch</Title>
    <Subtitle>
      Feel free to leave us a message and we will get back to you as soon as we
      can.
    </Subtitle>
    <Form />
  </Section>
)
