import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Image } from '@common/components'

const query = graphql`
  query {
    bracket: file(relativePath: { eq: "icons/bracket.png" }) {
      childImageSharp {
        fluid(maxWidth: 63) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

interface Props {
  className?: string
}

export const LeftBracket: React.StatelessComponent<Props> = ({ className }) => (
  <StaticQuery
    query={query}
    render={({ bracket }) => (
      <Image
        className={className}
        width="63px"
        image={bracket.childImageSharp.fluid}
      />
    )}
  />
)
