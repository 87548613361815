import { routeIds } from './routeIds'

export const routes = {
  home: `/#${routeIds.home}`,
  aboutUs: `/#${routeIds.aboutUs}`,
  team: `/#${routeIds.team}`,
  articlesAndVideos: `/#${routeIds.articlesAndVideos}`,
  community: `/#${routeIds.community}`,
  getInTouch: `/#${routeIds.getInTouch}`,
  blog: '/blog',
}
