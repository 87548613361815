import { styled } from '@styles'
import { theme } from '@theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h2`
  ${theme.typography.title}
  margin-bottom: 0.5rem;
  ${({ className }) => className};
`

export const Divider = styled.path`
  fill: ${theme.colors.primary};
  ${({ className }) => className};
`
