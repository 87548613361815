import { styled, media } from '@styles'

interface ContainerProps {
  backgroundColor?: string
  className?: string
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  ${({className}) => className};
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${media.medium`
    margin-left: 10%;
    margin-right: 10%;
  `}

  ${media.large`
    margin-left: 17%;
    margin-right: 17%;
  `}
`
