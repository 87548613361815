import * as React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { global } from '@styles'
import { theme } from '@theme'
import * as s from './page.styles'

interface Props {
  seoComponent: React.ReactNode
  headerComponent: React.ReactNode
}

export const Page: React.StatelessComponent<Props> = ({
  seoComponent,
  headerComponent,
  children,
}) => (
  <ThemeProvider theme={theme}>
    <Global styles={global} />
    {seoComponent}
    <s.Container>
      <s.Header>{headerComponent}</s.Header>
      <s.Content>{children}</s.Content>
    </s.Container>
  </ThemeProvider>
)
