import { styled } from '@styles'

interface Props {
  top?: string
  bottom?: string
  className?: string
}

export const BackgroundImageContainer = styled.div<Props>`
  position: relative;
  bottom: ${({ bottom }) => (bottom ? bottom : 'auto')};
  top: ${({ top }) => (top ? top : 'auto')};
  left: 0;
  width: 100%;
  ${({ className }) => className};
`
