import { styled, media } from '@styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
`

export const Title = styled.h2`
  ${({ theme }) => theme.typography.home.title};
  margin-top: 0;
  margin-bottom: 0;
`

export const Subtitle = styled.span`
  ${({ theme }) => theme.typography.home.subtitle};

  margin-top: 0;

  ${media.small`
    margin-top: 1rem;
  `};

  margin-bottom: 0;
`
