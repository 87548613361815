import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Section } from '@common/components'
import { routeIds } from '@common/constants'
import { Title } from './title'
import { LeftBracket } from './left-bracket'
import { Computer } from './computer'
import * as s from './home.styles'

const StyledLeftBracket = s.LeftBracket.withComponent(LeftBracket)
const StyledRightBracket = s.RightBracket.withComponent(LeftBracket)
const StyledComputer = s.Computer.withComponent(Computer)

const query = graphql`
  query {
    backgroundTop: file(relativePath: { eq: "backgrounds/home-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgroundBottom: file(
      relativePath: { eq: "backgrounds/home-bottom.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Home: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={({ backgroundTop, backgroundBottom }) => (
      <Section
        id={routeIds.home}
        backgrounds={{
          top: backgroundTop.childImageSharp.fluid,
          bottom: backgroundBottom.childImageSharp.fluid,
        }}
        classNames={{
          root: s.sectionStyles,
          backgrounds: {
            top: s.backgroundTopStyles,
            bottom: s.backgroundBottomStyles,
          },
        }}
      >
        <s.Container>
          <s.TitleContainer>
            <StyledLeftBracket />
            <Title
              title="Doers"
              subtitle={
                <>
                  <s.Subtitle>
                    We are an innovating team of Javascript experts,
                  </s.Subtitle>
                  <s.Subtitle>
                    passionate about turning your ideas into robust products.
                  </s.Subtitle>
                </>
              }
            />
            <StyledRightBracket />
          </s.TitleContainer>
          <StyledComputer />
        </s.Container>
      </Section>
    )}
  />
)
