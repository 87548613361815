import * as React from 'react'
import { navigate } from 'gatsby'
import * as s from './link.styles'

interface Props {
  title: string
  to: string
  onClick?: () => void
  className?: string
}

const onClickHandler = ({ to, onClick }: Props) => () => {
  navigate(to)
  onClick()
}

export const Link: React.StatelessComponent<Props> = props => (
  <s.Container onClick={onClickHandler(props)} className={props.className}>
    <s.Title>{props.title}</s.Title>
  </s.Container>
)
