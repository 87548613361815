import { styled, media } from '@styles'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;

  ${media.medium`
    width: 75%;
  `}

  ${media.extraLarge`
    width: 50%;
  `}
`
