import * as React from 'react'
import { Image } from '@common/components'
import * as s from './thumbnail.styles'

const Container = s.Container.withComponent('a')

interface Props {
  imageFluid
  title: string
  description: string
  link: string
  target?: '_blank' | '_self'
  className?: string
}

export const Thumbnail: React.StatelessComponent<Props> = ({
  imageFluid,
  title,
  description,
  link,
  target,
  className,
}) => (
  <Container className={className} href={link} target={target}>
    <Image width="148px" image={imageFluid} />
    <s.Title>{title} </s.Title>
    <s.Description>{description}</s.Description>
  </Container>
)

Thumbnail.defaultProps = {
  target: '_blank',
}
