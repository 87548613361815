import * as React from 'react'
import MaterialTextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import * as commoStyles from '../text-field.styles'
import * as s from './text-area.styles'

const TextField = s.Container.withComponent(MaterialTextField)

interface Props {
  value: string
  name: string
  onChange: (value: string, field: string) => void
  label: string
  classes?
  hasError?: boolean
  error?: string
}

const onChangeHandler = ({ onChange, name }: Props) => e => {
  onChange(e.target.value, name)
}

const InnerTextArea: React.StatelessComponent<Props> = props => (
  <commoStyles.Container>
    <TextField
      value={props.value}
      name={props.name}
      onChange={onChangeHandler(props)}
      label={props.label}
      variant="outlined"
      rows={10}
      multiline={true}
      fullWidth={true}
      error={props.hasError}
      InputLabelProps={{
        classes: {
          root: props.classes.label,
          focused: props.classes.focused,
          error: props.classes.error,
        },
      }}
      InputProps={{
        classes: {
          root: props.classes.input,
          focused: props.classes.focused,
          error: props.classes.error,
          notchedOutline: props.classes.notchedOutline,
        },
      }}
    />
    {props.hasError && (
      <FormHelperText>
        <commoStyles.Error>{props.error}</commoStyles.Error>
      </FormHelperText>
    )}
  </commoStyles.Container>
)

export const TextArea = withStyles(commoStyles.textFieldStyles)(InnerTextArea)
