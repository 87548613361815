import { format } from 'date-fns'
import { Article } from './viewModel'

export const mapPostsToArticles = (posts: any[]): Article[] =>
  Array.isArray(posts) ? posts.map(mapPostToArticle) : []

const mapPostToArticle = (post): Article =>
  Boolean(post)
    ? {
        id: post.node.id,
        imageFluid: post.node.image.fluid,
        date: format(post.node.createdAt, 'MMMM Do YYYY'),
        title: post.node.title,
        description: post.node.excerpt.childMarkdownRemark.html,
        link: post.node.path,
      }
    : null
