import * as React from 'react'
import GatsbyImage from 'gatsby-image'
import * as s from './image.styles'

interface Props {
  image
  width
  className?
}

export const Image: React.StatelessComponent<Props> = ({ image, width, className, children }) => (
  <s.Container width={width} className={className}>
    <GatsbyImage fluid={image} />
    {children}
  </s.Container>
)

Image.defaultProps = {
  image: {},
}
