import * as React from 'react'
import { Image } from '@common/components'
import { CircleImage } from './circle-image'
import * as s from './thumbnail.styles'

const StyledImage = s.Image.withComponent(Image)

interface Props {
  imageFluid
  titleOne: string
  titleTwo: string
  description: string
  className?: string
}

export const Thumbnail: React.StatelessComponent<Props> = ({
  imageFluid,
  titleOne,
  titleTwo,
  description,
  className,
}) => (
  <s.Container className={className}>
    <CircleImage>
      <StyledImage width="51px" image={imageFluid} />
    </CircleImage>
    <s.Title>
      <s.TitleOne>{titleOne}</s.TitleOne>
      <s.TitleTwo>{titleTwo}</s.TitleTwo>
    </s.Title>
    <s.Description>{description}</s.Description>
  </s.Container>
)
