import { styled, media } from '@styles'

export const Thumbnails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-top: 2rem;
  margin-bottom: 2rem;

  ${media.large`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  `}
`

export const Thumbnail = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 90%;

  ${media.medium`
    max-width: none;
  `}

  ${media.large`
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 35%;
    flex-grow: 1;
    flex-basis: auto;
  `}

  ${media.extraLarge`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    max-width: 17%;
  `}
`
