import {
  ValidationConstraints,
  Validators,
  createFormValidation,
} from 'lc-form-validation'

const validationConstraints: ValidationConstraints = {
  fields: {
    name: [{ validator: Validators.required }],
    email: [
      { validator: Validators.required },
      { validator: Validators.email },
    ],
    inquiry: [{ validator: Validators.required }],
  },
}

export const validations = createFormValidation(validationConstraints)
