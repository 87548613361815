import * as React from 'react'
import { MediaQuery } from '@common/components'
import { breakpoints } from '@styles'
import { Logo } from './logo'
import { Menu } from './menu'
import { MenuIcon } from './menu-icon'
import * as s from './header.styles'

const StyledMenuIcon = s.MenuIcon.withComponent(MenuIcon)

interface State {
  isOpenMenu: boolean
}
export class Header extends React.PureComponent<{}, State> {
  state: State = {
    isOpenMenu: false,
  }
  
  constructor(props) {
    super(props);
  }

  showMenuHandler = () => {
    this.setState({ isOpenMenu: true })
  }

  hideMenuHandler = () => {
    this.setState({ isOpenMenu: false })
  }

  render() {
    return (
      <MediaQuery breakpoint={breakpoints.large}>
        {isLargeResolution => (
          <s.Container>
            <Logo to="/" />
            {!isLargeResolution && (
              <StyledMenuIcon onClick={this.showMenuHandler} />
            )}
            <Menu
              onBlur={this.hideMenuHandler}
              onMenuClick={this.hideMenuHandler}
              isOpen={this.state.isOpenMenu}
              isLargeResolution={isLargeResolution}
            />
          </s.Container>
        )}
      </MediaQuery>
    )
  }
}
