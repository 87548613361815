import { FieldValidationResult } from 'lc-form-validation'
export interface ContactInfo {
  name: string
  email: string
  inquiry: string
}

export const createDefaultContactInfo = (): ContactInfo => ({
  name: '',
  email: '',
  inquiry: '',
})

export interface ContactInfoError {
  name: FieldValidationResult
  email: FieldValidationResult
  inquiry: FieldValidationResult
}

export const createDefaultContactInfoError = (): ContactInfoError => ({
  name: { ...new FieldValidationResult(), succeeded: true },
  email: { ...new FieldValidationResult(), succeeded: true },
  inquiry: { ...new FieldValidationResult(), succeeded: true },
})
