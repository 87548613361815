import { styled, media, clearBorder } from '@styles'

export const Container = styled.a`
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;

  ${({ theme }) => theme.typography.headerLink}
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;

  ${({ theme }) => media.large`
    ${clearBorder};
    &:hover {
      border-top-width: 0.4rem;
      border-top-color: ${theme.colors.light};
      border-top-style: solid;
    }
  `}
`

export const Title = styled.p`
  margin: 0;
  outline: none;
`
