import { styled, media } from '@styles'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.large`
    flex-wrap: nowrap;
  `}
`

export const Thumbnail = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.large`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  `}
`
