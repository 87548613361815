import * as React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Button } from '@common/components'
import * as vm from './viewModel'
import * as s from './post.styles'

const PostLink = s.PostLink.withComponent(Link)

const query = graphql`
  query {
    calendar: file(relativePath: { eq: "icons/calendar.png" }) {
      childImageSharp {
        fluid(maxWidth: 16) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

interface Props {
  post: vm.Post
  className?: string
}

export const Post: React.StatelessComponent<Props> = ({ post, className }) => (
  <StaticQuery
    query={query}
    render={({ calendar }) => (
      <s.Container className={className}>
        <s.TitleContainer>
          <s.Date imageSrc={calendar.childImageSharp.fluid.src}>
            {post.date}
          </s.Date>
          <s.Title>{post.title}</s.Title>
        </s.TitleContainer>
        <s.DescriptionContainer>
          <s.Description
            dangerouslySetInnerHTML={{ __html: post.description }}
          />
          <PostLink to={post.link}>
            <Button label="Read more" />
          </PostLink>
        </s.DescriptionContainer>
      </s.Container>
    )}
  />
)
