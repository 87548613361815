import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { routeIds } from '@common/constants'
import { Section, Title, Subtitle } from '@common/components'
import { Thumbnail } from './thumbnail'
import * as s from './about-us.styles'
import { colors } from '@theme'

const StyledThumbnail = s.Thumbnail.withComponent(Thumbnail)

const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/about-us.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rayImage: file(relativePath: { eq: "icons/ray.png" }) {
      childImageSharp {
        fluid(maxWidth: 51) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    codeImage: file(relativePath: { eq: "icons/code.png" }) {
      childImageSharp {
        fluid(maxWidth: 51) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    startImage: file(relativePath: { eq: "icons/star.png" }) {
      childImageSharp {
        fluid(maxWidth: 51) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const AboutUs: React.StatelessComponent = () => (
  <StaticQuery
    query={query}
    render={({ background, rayImage, codeImage, startImage }) => (
      <Section
        id={routeIds.aboutUs}
        backgroundColor={colors.light}
        backgrounds={{ bottom: background.childImageSharp.fluid }}
      >
        <Title>More about Us</Title>
        <Subtitle>
          We are committed to bringing your product to market as fast as
          possible. Besides being well founded in Lean/Agile methodology and
          DevOps, we rely on our seasoned experience with React.
        </Subtitle>
        <s.Thumbnails>
          <StyledThumbnail
            imageFluid={rayImage.childImageSharp.fluid}
            titleOne="We're"
            titleTwo="FAST"
            description="Working together as closely and efficiently as possible every step of the way, our hands-on team will deliver results within weeks."
          />
          <StyledThumbnail
            imageFluid={codeImage.childImageSharp.fluid}
            titleOne="We're"
            titleTwo="PROGRAMMERS"
            description="Both technical and professional, we know what we are talking about. "
          />
          <StyledThumbnail
            imageFluid={startImage.childImageSharp.fluid}
            titleOne="We're"
            titleTwo="RELIABLE"
            description="We´re a well-oiled team of highly skilled and experienced developers and we have been providing kickass solutions to interesting challenges for years."
          />
        </s.Thumbnails>
      </Section>
    )}
  />
)
